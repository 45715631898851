import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
import AuthInput from '@/components/auth/AuthInput.vue';
import { LicenseModule } from '@/store/modules/license';
let AuthLicensePage = class AuthLicensePage extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.canSubmit = false;
        this.license = '';
    }
    updateCanSubmit() {
        this.canSubmit = !!this.license;
    }
    async getTrial() {
        this.$router.replace({
            name: 'auth.trial',
        });
    }
    async submit() {
        try {
            this.$loading();
            await LicenseModule.submitLicense(this.license);
            this.$toast.success('License key is valid.');
            this.$router.replace('/');
        }
        catch (error) {
            this.$toast.error('Provided license key is not valid.');
        }
        finally {
            this.$loading(false);
        }
    }
};
__decorate([
    Watch('license', { immediate: true })
], AuthLicensePage.prototype, "updateCanSubmit", null);
AuthLicensePage = __decorate([
    Component({
        components: {
            AuthInput,
        },
    })
], AuthLicensePage);
export default AuthLicensePage;
