import { __decorate } from "tslib";
import { Vue, Component, Prop, Model } from 'vue-property-decorator';
let AuthInput = class AuthInput extends Vue {
    get stateClass() {
        if (this.state === null) {
            return '';
        }
        return this.state ? 'auth-input--valid' : 'auth-input--invalid';
    }
};
__decorate([
    Prop({
        required: true,
    })
], AuthInput.prototype, "icon", void 0);
__decorate([
    Prop({
        default: null,
    })
], AuthInput.prototype, "state", void 0);
__decorate([
    Model('change')
], AuthInput.prototype, "model", void 0);
AuthInput = __decorate([
    Component({
        inheritAttrs: false,
    })
], AuthInput);
export default AuthInput;
